import { Component } from '@angular/core';

@Component({
  selector: 'app-eula-document',
  templateUrl: './eula-document.component.html',
  styleUrls: ['./eula-document.component.scss'],
})
export class EulaDocumentComponent {
  constructor() {}
}
