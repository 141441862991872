import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccessService } from '@app-core/services/access/access.service';
import { DataService } from '@app-core/services/data/data.service';
import { UserRoleManageService } from '@app-user-management/service/user-role-manage.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sso-dialog',
  templateUrl: './sso-dialog.component.html',
  styleUrls: ['./sso-dialog.component.scss'],
})
export class SsoDialogComponent implements OnInit, OnDestroy {
  public loader: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private userHash: string = '';

  constructor(
    public dialogRef: MatDialogRef<SsoDialogComponent>,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data,
    private accessService: AccessService,
    private userService: UserRoleManageService
  ) {}

  ngOnInit(): void {
    this.userHash = this.accessService.getLoginInfo().userHash;
  }

  public rejectConsent() {
    this.dialogRef.close(false);
  }

  public acceptConsent() {
    this.loader = true;
    this.createSSOUser()
      .pipe(
        finalize(() => {
          this.loader = false;
          this.dialogRef.close(true);
        })
      )
      .subscribe(
        () => {},
        (error) => {
          console.error('Error creating SSO user:', error);
        }
      );
  }

  private createSSOUser() {
    this.loader = true;
    const {
      userMetadata: { dateFormat = 'MM/DD/YYYY HH:mm:ss', timezone = 'Local', metricUnit = 'Miles', language = 'en', theme = 'light' },
    } = this.accessService.getLoginInfo();
    const body = {
      payload: {
        userPreferences: {
          metricUnit: metricUnit === 'Miles' ? 'mi' : 'km',
          dateFormat: dateFormat,
          language: language,
          theme: theme,
          timezone: timezone,
        },
        consentTimestamp: this.data.consentTime,
      },
    };
    return this.userService.createSSOUser(this.userHash, body).pipe(
      finalize(() => {
        this.loader = false;
      }),
      takeUntil(this.ngUnsubscribe)
    );
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
