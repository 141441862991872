import { Component, Input, OnInit } from '@angular/core';
import { FooterComponent, FooterComponentData } from '../footer.model';

@Component({
  selector: 'app-satrack-footer',
  templateUrl: './satrack-footer.component.html',
  styleUrls: ['./satrack-footer.component.scss'],
})
export class SatrackFooterComponent implements FooterComponent, OnInit {
  @Input() data: FooterComponentData;
  constructor() {}

  public ngOnInit() {
    /**
     * NOTE:
     * Dont replicate this. This overrides the parent CSS in case when sartrack footer is rendered.
     * This will breach the scope and will be difficult to debug later.
     */
    const css =
      '.landing-page {height: 100vh; overflow: auto} .content-large-footer {min-height: 55vh} .content-text-large-footer {top: 50% !important}';
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
  }
}
