import { Component, Input } from '@angular/core';
import { FooterComponent, FooterComponentData } from '../footer.model';

@Component({
  selector: 'app-rearviewsafety-footer',
  templateUrl: './rearviewsafety-footer.component.html',
  styleUrls: ['./rearviewsafety-footer.component.scss'],
})
export class RearviewsafetyFooterComponent implements FooterComponent {
  @Input() data: FooterComponentData;
  public supportEmail: string = 'RVS.DVR@safefleet.net';
}
