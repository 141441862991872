import { Component, Input } from '@angular/core';
import { FooterComponent, FooterComponentData } from '../footer.model';

@Component({
  selector: 'app-safefleet-footer',
  templateUrl: './safefleet-footer.component.html',
  styleUrls: ['./safefleet-footer.component.scss'],
})
export class SafefleetFooterComponent implements FooterComponent {
  @Input() data: FooterComponentData;
}
