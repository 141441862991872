import { Component, OnInit } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { CLIENT_CONFIG } from '@config/config';
import { IframeAuthService } from '../../services/iframe-auth/iframe-auth.service';
import { LoginTypes } from '@app-core/services/google-tag-manager/google-tag-manager.service';
import { DataService } from '@app-core/services/data/data.service';
import { NEVER } from 'rxjs';

@Component({
  selector: 'app-auth0-sso-login',
  templateUrl: './auth0-sso-login.component.html',
  styleUrls: ['./auth0-sso-login.component.scss'],
})
export class Auth0SsoLoginComponent implements OnInit {
  constructor(private iframeAuthService: IframeAuthService, private dataService: DataService) {}

  ngOnInit(): void {
    this.checkSession();
  }

  private checkSession() {
    this.iframeAuthService
      .auth0CheckSession()
      .pipe(
        switchMap((authResult) => {
          return this.iframeAuthService.authenticateSSOUser(CLIENT_CONFIG.clientName, authResult.accessToken).pipe(
            catchError(() => {
              this.iframeAuthService.logout();
              return NEVER;
            })
          );
        })
      )
      .subscribe(
        (res) => {
          const loginInfo = { ...res, loginType: 'fleetmanager' };
          this.dataService.userLogin.next({ loginInfo, redirectUrl: 'home', loginType: LoginTypes.sso });
        },
        () => {
          this.iframeAuthService.navigateToClientLoginPage();
        }
      );
  }
}
