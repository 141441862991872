import { Component, Input } from '@angular/core';
import { FooterComponent, FooterComponentData } from '../footer.model';

@Component({
  selector: 'app-taabi',
  templateUrl: './taabi.component.html',
  styleUrls: ['./taabi.component.scss'],
})
export class TaabiComponent implements FooterComponent {
  @Input() data: FooterComponentData;
}
