import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataService } from '@app-core/services/data/data.service';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
  @ViewChild('multiUserSearch') inputelement: ElementRef;
  @Input() public form: FormGroup;
  @Input() public config: any = {}; // This will have boolean and string

  public currentMetricUnit: string = null;
  public filteredDropDown: string[] = [];
  private alreadySelectedvalues: string[] = [];

  public selectedOptionsKeyValue: { key: string; value: string }[] = [];

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.dataService._currentMetricUnit.subscribe((value: string) => {
      if (value) {
        this.currentMetricUnit = value;
      }
    });
    this.filteredDropDown = this.config.options;
  }

  public onOpenChange() {
    if (this.inputelement) {
      this.inputelement.nativeElement.value = '';
      this.filteredDropDown = this.config.options;
    }
    this.alreadySelectedvalues = this.form.get(this.config.key)?.value;
    this.selectedOptionsKeyValue = this.alreadySelectedvalues
      .map((value: string) => {
        const option = this.config.options.find((opt) => opt.key === value);
        return option ? { key: option.key, value: option.value } : null;
      })
      .filter((option) => option !== null) as { key: string; value: string }[];
  }

  onInputChange(event: any) {
    // event has multiple types of events
    const searchInput = event.target.value.toLowerCase();
    let audioAlertList = [];
    audioAlertList = this.config.options;
    this.filteredDropDown = audioAlertList.filter((audio) => {
      const audioAlertNameLower = audio.value.toLowerCase();
      return audioAlertNameLower.includes(searchInput);
    });
  }

  public onOptionClick(option: any) {
    const optionKeyValue = { key: option.key, value: option.value };

    // Check if the option is already selected
    const existingIndex = this.selectedOptionsKeyValue.findIndex((item) => item.key === optionKeyValue.key);

    if (existingIndex > -1) {
      // Option is already selected, so remove it
      this.selectedOptionsKeyValue.splice(existingIndex, 1);
    } else {
      // Option is not selected, so add it
      this.selectedOptionsKeyValue.push(optionKeyValue);
    }
    this.form.get(this.config.key)?.setValue(this.selectedOptionsKeyValue.map((item) => item.key));
  }
}
