import { Component, Output, EventEmitter } from '@angular/core';
import { DEFAULT_DURATION_LIST } from '@app-core/constants/constants';
import { Days } from '@app-core/models/core.model';
import { DurationRangeService } from '@app-core/services/duration-range/duration-range.service';

@Component({
  selector: 'app-duration-list',
  templateUrl: './duration-list.component.html',
  styleUrls: ['./duration-list.component.scss'],
})
export class DurationListComponent {
  public durationList = DEFAULT_DURATION_LIST;
  public selectedItem: Days;

  @Output() durationSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(private durationService: DurationRangeService) {
    this.durationList = this.durationService.getDurationList();
  }

  public onSelectionChange(event) {
    this.selectedItem = event?.options?.[0]?.value;
    this.durationSelected.emit(this.selectedItem);
  }
}
