import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  DEFAULT_DARK_THEME_BORDER_COLOR,
  DEFAULT_DARK_THEME_TEXT_COLOR,
  DEFAULT_LIGHT_THEME_BORDER_COLOR,
  DEFAULT_LIGHT_THEME_TEXT_COLOR,
  EVENTS_CONFIG,
  EVENT_STREAK_CHART_OPTIONS,
  MILES_TO_KILOMETERS_CONVERSION,
} from '@app-core/constants/constants';
import { DataService } from '@app-core/services/data/data.service';
import { EventStreakParams, EventStreakResponse, Streak, StreakInfo } from '@app-driver-management/common/driver-management.model';
import { DriverManagementService } from '@app-driver-management/services/driver-management.service';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartConfiguration, ChartItem, ChartTypeRegistry, TooltipItem } from 'chart.js/auto';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-event-streak',
  templateUrl: './event-streak.component.html',
  styleUrls: ['./event-streak.component.scss'],
})
export class EventStreakComponent implements OnInit, OnChanges {
  @Input()
  public startDate: string;
  @Input()
  public endDate: string;
  @Input()
  public driverId: string;
  @Input()
  public fleetId: string;
  @Input()
  public currentTheme: string = 'light';
  @Input()
  public showTitle: boolean = true;

  public loader: boolean = false;
  public showChart: boolean = false;
  public currentMetricUnit: string = '';

  private eventStreak: EventStreakResponse;
  private currentLanguage: string;
  private chart: Chart;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    private elementRef: ElementRef,
    private driverManagementService: DriverManagementService
  ) {}

  public ngOnInit(): void {
    this.getEventStreaks();

    this.dataService._currentMetricUnit.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.currentMetricUnit = value;
      this.generateChart();
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.currentTheme) {
      this.changeTheme();
    }
  }

  private getEventStreaks(): void {
    this.loader = true;
    const params: EventStreakParams = {
      startDate: this.startDate,
      endDate: this.endDate,
      fleetId: this.fleetId,
      driverId: this.driverId,
    };
    this.driverManagementService
      .getDriverEventStreak(params)
      .pipe(
        finalize(() => {
          this.loader = false;
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        (res: EventStreakResponse) => {
          this.eventStreak = res;
          this.generateChart();
        },
        () => {
          this.showChart = false;
        }
      );
  }

  private generateChart(): void {
    this.translate.stream('eventStreak').subscribe((langData: string) => {
      const streak = this.eventStreak?.streaks || ({} as StreakInfo);
      const totalIncidentCount = Object.keys(streak).length;
      this.showChart = !!totalIncidentCount;

      const combinedEventsList = this.dataService.modifyFleeEvents(true);
      const combinedEventsConfig = { ...EVENTS_CONFIG, ...this.dataService.transformObject(combinedEventsList) };
      const eventsToShow = Object.keys({ ...EVENTS_CONFIG, ...combinedEventsConfig });

      const seriesData = Object.entries(streak)
        .filter(([eventType, value]: [string, Streak]) => eventsToShow.includes(eventType) && value.longestStreak > 0)
        .map(([eventType, value]: [string, Streak]) => {
          return {
            label: this.translate.instant(combinedEventsConfig[eventType].label),
            backgroundColor: combinedEventsConfig[eventType].color,
            value:
              this.currentMetricUnit === 'Miles'
                ? (value?.longestStreak * MILES_TO_KILOMETERS_CONVERSION).toFixed(2)
                : value?.longestStreak,
          };
        })
        .sort((a, b) => (b.label - a.label > 0 ? 1 : -1));

      if (seriesData.length) {
        this.updateEventStreakGraph(langData, seriesData);
      } else {
        this.showChart = false;
      }
    });
  }

  private updateEventStreakGraph(langData, seriesData): void {
    seriesData.map((seriesDataKey, seriesindex) => {
      if (langData.mapData) {
        langData.mapData.map((languageDataKey, languageIndex) => {
          if (seriesDataKey.label === languageDataKey.name) {
            seriesData[seriesindex].label = langData.mapData[languageIndex].Key;
          }
        });
      }
    });
    if (this.chart) {
      this.chart.destroy();
    }
    const ctx: ChartItem = this.elementRef.nativeElement.querySelector(`#eventStreakChart`);
    const chartStatus = Chart.getChart('eventStreakChart');
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    let chartData = {
      ...EVENT_STREAK_CHART_OPTIONS,
    };
    if (ctx) {
      chartData.options.scales.y.title = {
        text: `${langData.eventStreakYAxis}`,
        ...chartData.options.scales.y.title,
      };

      chartData.options.scales.x.title = {
        text: this.currentMetricUnit === 'Miles' ? `${langData.eventStreakXAxisMiles}` : `${langData.eventStreakXAxisKilometers}`,
        ...chartData.options.scales.x.title,
      };

      const chart = new Chart(ctx, {
        ...chartData,
        data: {
          labels: seriesData.map((x) => x.label),
          datasets: [
            {
              label: langData.eventStreakGraphValuesToolip,
              data: seriesData.map((x) => x.value),
              backgroundColor: seriesData.map((x) => x.backgroundColor),
              barPercentage: 0.5,
            },
          ],
        },
      } as ChartConfiguration);
      this.chart = chart;
    }

    this.dataService._currentLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: string) => {
      if (value) {
        this.currentLanguage = value;
        if (this.currentLanguage !== 'en') {
          this.chart.options.plugins.tooltip.callbacks.label = (event: TooltipItem<keyof ChartTypeRegistry>) => {
            const { dataset = [], dataIndex = 0 } = event[0] || event;
            const { data = [] } = dataset;
            const chars = { ',': '.', '.': ',' };
            const distance = data[dataIndex] ? data[dataIndex].toString().replace(/[,.]/g, (m) => chars[m]) : 0;
            return [`${langData.eventStreakGraphValuesToolip}: ${distance} ${this.currentMetricUnit === 'Miles' ? 'mi' : 'km'}`];
          };
          this.chart.update();
        } else {
          this.chart.options.plugins.tooltip.callbacks.label = (event: TooltipItem<keyof ChartTypeRegistry>) => {
            const { dataset, dataIndex = 0 } = event || {};
            const { data = [] } = dataset;
            return [`${langData.eventStreakGraphValuesToolip}: ${data[dataIndex]} ${this.currentMetricUnit === 'Miles' ? 'mi' : 'km'}`];
          };
        }
      }
      this.chart.update();
    });
    this.dataService._currentMetricUnit.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: string) => {
      if (value) {
        this.currentMetricUnit = value;
        this.chart.options.scales.y['title'] = {
          ...this.chart.options.scales.y['title'],
          text: `${langData.eventStreakYAxis}`,
        };
        this.chart.options.scales.x['title'] = {
          ...this.chart.options.scales.x['title'],
          text: this.currentMetricUnit === 'Miles' ? `${langData.eventStreakXAxisMiles}` : `${langData.eventStreakXAxisKilometers}`,
        };
        this.chart.update();
      }
    });
  }

  private changeTheme(): void {
    if (this.chart) {
      if (this.currentTheme === 'light') {
        this.chart.options.scales.y.grid.color = DEFAULT_LIGHT_THEME_BORDER_COLOR;
        this.chart.options.scales.x.ticks.color = DEFAULT_LIGHT_THEME_TEXT_COLOR;
        this.chart.options.scales.y.ticks.color = DEFAULT_LIGHT_THEME_TEXT_COLOR;
        this.chart.options.scales.y['title'].color = DEFAULT_LIGHT_THEME_TEXT_COLOR;
        this.chart.options.scales.x['title'].color = DEFAULT_LIGHT_THEME_TEXT_COLOR;
      } else {
        this.chart.options.scales.y.grid.color = DEFAULT_DARK_THEME_BORDER_COLOR;
        this.chart.options.scales.x.ticks.color = DEFAULT_DARK_THEME_TEXT_COLOR;
        this.chart.options.scales.y.ticks.color = DEFAULT_DARK_THEME_TEXT_COLOR;
        this.chart.options.scales.y['title'].color = DEFAULT_DARK_THEME_TEXT_COLOR;
        this.chart.options.scales.x['title'].color = DEFAULT_DARK_THEME_TEXT_COLOR;
      }
      this.chart.update();
    }
  }
}
