import { Component, Input } from '@angular/core';
import { FooterComponent, FooterComponentData } from '../footer.model';

@Component({
  selector: 'app-quantatec',
  templateUrl: './quantatec.component.html',
  styleUrls: ['./quantatec.component.scss'],
})
export class QuantatecComponent implements FooterComponent {
  @Input() data: FooterComponentData;
}
