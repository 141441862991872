import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { DurationListComponent } from '../duration-list/duration-list.component';
import { BREAKPOINTS_SMALL, MAX_SELECTABLE_DATE_RANGE_IN_DAYS } from '@app-core/constants/constants';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DateService } from '@app-core/services/date/date.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { Days, DurationRange } from '@app-core/models/core.model';
import { Platform } from '@angular/cdk/platform';
import { DurationRangeService } from '@app-core/services/duration-range/duration-range.service';

@Component({
  selector: 'app-duration-range',
  templateUrl: './duration-range.component.html',
  styleUrls: ['./duration-range.component.scss'],
})
export class DurationRangeComponent implements OnInit, OnChanges {
  public maxStartDate: string;
  public maxEndDate: string;
  public minStartDate: string;
  public form = null;
  public isTouchDevice = false;
  public selectedDuration: Days;
  public customDurationRangeSettings = null;
  private componentRef;
  private now: Date;
  private maxDateSet: boolean = false;
  private readonly DATA_RETENTION_PERIOD = this.durationService.dataRetentionDays;
  private readonly MAX_SELECTABLE_DATE_RANGE_IN_DAYS = Math.min(this.DATA_RETENTION_PERIOD, MAX_SELECTABLE_DATE_RANGE_IN_DAYS);

  @Input()
  public durationRange: DurationRange;

  @Input()
  set customSettings(value) {
    let { customDurationList = null, dataRetentionPeriod = 0, maxSelectableDateRange = 0 } = value;
    dataRetentionPeriod = Math.min(dataRetentionPeriod, this.DATA_RETENTION_PERIOD);
    maxSelectableDateRange = Math.min(dataRetentionPeriod, maxSelectableDateRange);
    const verifiedList = this.durationService.getCustomDurationList(customDurationList, dataRetentionPeriod);
    this.customDurationRangeSettings = { customDurationList: verifiedList, dataRetentionPeriod, maxSelectableDateRange };
  }

  @Output() durationChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('picker') private picker: MatDateRangePicker<any>;

  constructor(
    private dateService: DateService,
    private durationService: DurationRangeService,
    private viewContainerRef: ViewContainerRef,
    private platform: Platform,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.now = new Date();
    this.form = new FormGroup({
      startDate: new FormControl(this.now, [Validators.required]),
      endDate: new FormControl(this.now, [Validators.required, this.endDateValidator()]),
    });
    this.setMinMaxDateRanges();
    this.setDateRange(this.durationRange);

    this.breakpointObserver.observe([...BREAKPOINTS_SMALL]).subscribe((state: BreakpointState) => {
      this.isTouchDevice = state.matches;
    });
    this.isTouchDevice = this.platform.ANDROID || this.platform.IOS;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.durationRange?.currentValue) {
      this.setDateRange(this.durationRange);
    }
  }

  private endDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const endDate = control.value;
      const startDate = this.form?.get('startDate').value;
      if (endDate && startDate) {
        const maxDate = new Date(startDate);
        this.customDurationRangeSettings?.maxSelectableDateRange
          ? maxDate.setDate(startDate.getDate() + this.customDurationRangeSettings?.maxSelectableDateRange)
          : maxDate.setDate(startDate.getDate() + MAX_SELECTABLE_DATE_RANGE_IN_DAYS);
        if (endDate.getTime() > maxDate.getTime()) {
          return { endDateInvalid: true };
        }
      }
      return null;
    };
  }

  private setMinMaxDateRanges() {
    this.maxStartDate = this.maxEndDate = new Date().toISOString();
    this.minStartDate = this.customDurationRangeSettings?.dataRetentionPeriod
      ? this.dateService.subtractDays(this.maxStartDate, this.customDurationRangeSettings.dataRetentionPeriod - 1)
      : this.dateService.subtractDays(this.maxStartDate, this.DATA_RETENTION_PERIOD - 1);
  }

  private setMaxDate() {
    const { startDate } = this.form?.value || {};
    if (startDate) {
      const maxDate = new Date(startDate);
      this.customDurationRangeSettings?.maxSelectableDateRange
        ? maxDate.setDate(startDate.getDate() + this.customDurationRangeSettings?.maxSelectableDateRange)
        : maxDate.setDate(startDate.getDate() + this.MAX_SELECTABLE_DATE_RANGE_IN_DAYS);
      this.maxEndDate = maxDate.getTime() > this.now.getTime() ? this.now.toISOString() : maxDate.toISOString();
    }
  }

  private setDateRange(durationRange: DurationRange) {
    const { days, startDate, endDate } = durationRange;

    if (days && startDate && endDate) {
      this.form?.patchValue({
        startDate: startDate,
        endDate: endDate,
      });
      this.selectedDuration = days;
    }

    this.setMaxDate();
  }

  /**
   * Function called on change of start Date
   *
   * Reset endDate if start Date is greater than endDate
   */
  public onStartDateChange() {
    const { startDate, endDate } = this.form.value;
    this.selectedDuration = 'custom';
    this.componentRef.instance.selectedItem = this.selectedDuration;
    if (startDate && endDate && startDate.getTime() > endDate.getTime()) {
      this.form.patchValue({
        endDate: null,
      });
    }
    this.setMaxDate();
    this.maxDateSet = true;
  }

  public onEndDateChange() {
    if (!this.maxDateSet) {
      this.setMaxDate();
      this.maxDateSet = true;
    }
  }

  public open() {
    this.insertDurationList();
    this.form.valid ? (this.maxEndDate = this.now.toISOString()) : this.setMaxDate();
  }

  public close() {
    if (!this.form.valid || !this.selectedDuration) {
      return;
    }

    const { startDate, endDate } = this.form.value;
    const now = new Date(this.maxStartDate);
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliSeconds = now.getMilliseconds();
    const value = {
      days: this.selectedDuration,
      startDate: new Date(startDate.setHours(hours, minutes, seconds, milliSeconds)),
      endDate: new Date(endDate.setHours(hours, minutes, seconds, milliSeconds)),
    };

    this.durationChange.emit(value);
    this.componentRef.destroy();
    this.componentRef = null;
    this.maxDateSet = false;
  }

  public insertDurationList() {
    var datepickerContent = document.querySelector('mat-datepicker-content');
    if (datepickerContent) {
      const componentRef = this.viewContainerRef.createComponent(DurationListComponent);
      this.componentRef = componentRef;

      // Get the root HTMLElement of the dynamically created component
      const contentElement = (componentRef.hostView as any).rootNodes[0] as HTMLElement;

      const matCalendar = datepickerContent.querySelector('.mat-calendar');
      matCalendar.parentElement.style.flexDirection = 'row';
      if (this.isTouchDevice) {
        matCalendar.parentElement.style.height = 'auto';
        const durationList = contentElement.querySelector('.durationListContainer') as HTMLElement;
        durationList.style.width = 'auto';
      }
      matCalendar.parentNode.insertBefore(contentElement, matCalendar);

      componentRef.instance.selectedItem = this.selectedDuration;
      if (this.customDurationRangeSettings?.customDurationList) {
        componentRef.instance.durationList = this.customDurationRangeSettings.customDurationList;
      }

      const subscription = componentRef.instance.durationSelected.subscribe((days) => {
        this.getDateRange(days);
      });

      componentRef.onDestroy(() => {
        subscription.unsubscribe();
      });
    }
  }

  private getDateRange(days: Days): void {
    let startDate, endDate;

    if (typeof days === 'number') {
      ({ from: startDate, to: endDate } = this.dateService.getDateRange(days));
      this.picker.close();
    } else if (days === 'yesterday') {
      ({ from: startDate, to: endDate } = this.dateService.getDateRange(2));
      endDate.setDate(endDate.getDate() - 1);
      this.picker.close();
    } else if (days === 'custom') {
      startDate = endDate = new Date();
    }

    this.setDateRange({ days, startDate, endDate });
  }
}
