import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateStorageSpace',
})
export class AbbreviateStorageSpacePipe implements PipeTransform {
  transform(value: number = 0): string {
    if (value < 1000) {
      return Math.round(value) + ' B';
    }
    let tranformedValue: string;
    const decPlaces = Math.pow(10, 2); // Decimal places fixed to 2
    const abbrev = ['KB', 'MB', 'GB', 'TB'];

    for (let i = abbrev.length - 1; i >= 0; i--) {
      // 1000 or 1000000 etc.,
      const size = Math.pow(10, (i + 1) * 3);
      // Do abbreviation only if number is bigger or equal to size
      if (value >= size) {
        // Round it to 2 decimal places eg.,12345.44 to 12.35
        value = Math.round((value * decPlaces) / size) / decPlaces;
        // Handle special case eg.,999.99KB to 1MB and any 999.32MB to 1GB
        if ((value === 1000 || Math.floor(value) === 999) && i < abbrev.length - 1) {
          value = 1;
          i++;
        }
        tranformedValue = value + ' ' + abbrev[i];
        break;
      }
    }
    return tranformedValue;
  }
}
