import { Component } from '@angular/core';

@Component({
  selector: 'app-unauthorized-error-page',
  templateUrl: './unauthorized-error-page.component.html',
  styleUrls: ['./unauthorized-error-page.component.scss'],
})
export class UnauthorizedErrorPageComponent {
  constructor() {}
}
