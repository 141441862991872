import { Injectable, OnDestroy } from '@angular/core';
import { DataService } from '../data/data.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class ThemeService implements OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  constructor(private dataService: DataService, private overlay: OverlayContainer) {}

  public applyThemeToOverlay() {
    this.dataService._currentTheme.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: string) => {
      if (value === 'dark') {
        this.overlay.getContainerElement().classList.add('dark-theme');
      } else {
        this.overlay.getContainerElement().classList.remove('dark-theme');
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
