import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@app-core/services/data/data.service';
import { State, getSideNavigationConfigState } from '@app-shared/reducers';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { IncidentModalComponent } from '../incident-modal/incident-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GetPositiveActionsParams } from '@app-driver-management/common/driver-management.model';
import { DriverManagementService } from '@app-driver-management/services/driver-management.service';
import { HomeService } from '@app-home/services/home.service';
@Component({
  selector: 'app-recognized-events',
  templateUrl: './recognized-events.component.html',
  styleUrls: ['./recognized-events.component.scss'],
})
export class RecognizedEventsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('recognizedEvents', { static: true })
  public recognizedEvents: ElementRef;
  @ViewChild('paginator', { static: true })
  public paginator: MatPaginator;

  @Input()
  public startDate: string;
  @Input()
  public endDate: string;
  @Input()
  public driverId: string;
  @Input()
  public fleetId: string;
  @Input()
  public timezone = '';
  @Input()
  public dateFormat = '';
  @Input()
  public showTitle: boolean = true;

  public totalCount: number = 0;
  public loader: boolean = true;
  public dummyVideoList: undefined[];
  public carouselCardWidth: number = 0;
  public pageSize: number = 5;
  public videoListDataSource = new MatTableDataSource<any>([]);

  private carouselContainerWidth: number = 0;
  private violations = [];
  private violationOffset: number = 0;
  private dvrOffset: number = 0;
  private totalViolationsCount: number = 0;
  private totalDVRCount: number = 0;
  private violationsData = [];
  private dvrData = [];

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private unsubscribeOnDestroy: Subject<void> = new Subject<void>();

  constructor(
    public dataService: DataService,
    private cdRef: ChangeDetectorRef,
    private store: Store<State>,
    private dialog: MatDialog,
    private driverManagementService: DriverManagementService,
    private homeService: HomeService
  ) {}

  public ngOnInit(): void {
    this.store
      .select(getSideNavigationConfigState)
      .pipe(takeUntil(this.unsubscribeOnDestroy), delay(500))
      .subscribe(() => {
        this.checkCarouselConfiguration();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes?.startDate && changes.startDate?.currentValue) || (changes?.endDate && changes.endDate?.currentValue)) {
      this.paginator.firstPage();
      if (this.driverId) {
        this.resetData();
        this.getPositiveEvents(false, true);
      }
    } else {
      this.paginator.firstPage();
      this.getPositiveEvents(false, true);
    }
  }

  public ngAfterViewInit(): void {
    this.paginator.page.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: PageEvent) => {
      this.loader = true;
      this.paginator.pageIndex = event.pageIndex;
      this.videoListDataSource.data = [];
      this.violationOffset = event.pageIndex * this.pageSize;
      this.getPositiveEvents();
    });
    this.checkCarouselConfiguration();
  }

  private checkCarouselConfiguration(): void {
    this.carouselContainerWidth = this.recognizedEvents.nativeElement.offsetWidth;
    this.dummyVideoList = new Array(this.pageSize).fill(undefined);
    this.calculateSlidesToShowOnScreen();
    this.cdRef.detectChanges();
  }

  public showMedia(event: any = {}): void {
    if (event.isDVR) {
      const { latitude = 0, longitude = 0 } = event.firstLocation || {};
      event = {
        ...event,
        latitude,
        longitude,
        eventVideoFilename: event.response.link,
        videoDetails: {
          videoResolution: event.videoResolution,
        },
      };
    }

    const filteredDataForPagination = this.dataService.modifyDataBasedOnPageSize(
      this.videoListDataSource.data,
      event.positionIndex,
      this.pageSize
    );
    const filteredDataPaginationIndex = this.dataService.modifyPageIndexBasedOnPageSize(event.positionIndex, this.pageSize);

    this.dialog.open(IncidentModalComponent, {
      panelClass: ['incident-modal', 'mobile-modal'],
      position: { top: '24px', bottom: '24px' },
      autoFocus: false,
      disableClose: true,
      data: {
        source: 'Fleet',
        allEvents: filteredDataForPagination,
        currentIndex: filteredDataPaginationIndex,
      },
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.videoListDataSource.disconnect();

    this.unsubscribeOnDestroy.next();
    this.unsubscribeOnDestroy.complete();
  }

  private calculateSlidesToShowOnScreen(): void {
    const { carouselCardWidth, totalCardsInDisplay } = this.dataService.getCarouselConfiguration(this.carouselContainerWidth);
    this.carouselCardWidth = carouselCardWidth - (24 - 24 / totalCardsInDisplay);
    this.pageSize = totalCardsInDisplay;
  }

  private getPositiveEvents(isRefresh?: boolean, isInitial?: boolean): void {
    this.loader = true;

    const params: GetPositiveActionsParams = {
      startDate: this.startDate,
      endDate: this.endDate,
      driverId: this.driverId,
      fleetId: this.fleetId,
      limit: this.pageSize,
      offset: this.violationOffset,
      includeOnlyPositiveAction: true,
    };

    this.driverManagementService
      .getPositiveActions(params, isRefresh)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (isInitial) {
            this.totalViolationsCount = res.totalCount;
            this.getDVREvents(false, isInitial);
            this.totalCount = this.totalDVRCount + this.totalViolationsCount;
          }
          this.violationsData = res.rows || [];
          this.violationsData.forEach((e) => {
            const isVideo = e?.mediaFiles?.length && e?.mediaFiles[0]?.videoDetails ? true : false;
            e.positiveAction = true;
            e.isVideo = isVideo;
          });

          this.violationsData = this.violationsData.map((x, index) => {
            return { ...x, positionIndex: index };
          });

          if (this.violationsData.length < this.pageSize && this.totalCount) {
            this.violations = this.violationsData;
            this.loader = false;
            this.getDVREvents();
          } else {
            this.violations = this.violationsData;
            this.videoListDataSource.data = this.violations;
            this.loader = false;
          }
        },
        () => {
          this.loader = false;
          this.violations = [];
          this.videoListDataSource.data = this.violations;
        }
      );
  }

  private getDVREvents(isRefresh?: boolean, initial?: boolean): void {
    this.loader = true;

    const initialParams: GetPositiveActionsParams = {
      startDate: this.startDate,
      endDate: this.endDate,
      driverId: this.driverId,
      fleetId: this.fleetId,
      includeOnlyPositiveAction: true,
    };

    const paramsLimit = Math.abs(this.violationsData?.length - this.pageSize);

    const params: GetPositiveActionsParams = {
      startDate: this.startDate,
      endDate: this.endDate,
      driverId: this.driverId,
      fleetId: this.fleetId,
      limit: paramsLimit ? paramsLimit : this.pageSize,
      offset: this.dvrOffset,
      includeOnlyPositiveAction: true,
    };

    this.homeService
      .getFleetDvrRequests(initial ? initialParams : params, isRefresh)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          if (initial) {
            const { totalCount } = res;
            this.totalDVRCount = totalCount;
            this.totalCount = this.totalViolationsCount + this.totalDVRCount;
          } else {
            this.dvrOffset = paramsLimit ? paramsLimit : this.pageSize;
            // Processing the response of getFleetDvrRequests
            if (res && res.data && res.data.uploadRequests && res.data.uploadRequests.length > 0) {
              const updatedVideoRequestList = res.data.uploadRequests.map((videoRequest) => ({
                ...videoRequest,
                positiveAction: true,
                isVideo: videoRequest?.mediaFiles?.length && videoRequest?.mediaFiles[0]?.videoDetails ? true : false,
                isDvrEvent: true,
                eventTypeLabel: 'Video Request',
                latitude: videoRequest?.firstLocation?.latitude,
                longitude: videoRequest?.firstLocation?.longitude,
              }));
              this.dvrData = updatedVideoRequestList;
            }
          }

          if (this.dvrData.length) {
            this.violations = [...this.violationsData, ...this.dvrData];

            this.violations = this.violations.map((x, index) => {
              return { ...x, positionIndex: index };
            });
            this.videoListDataSource.data = this.violations;
            this.loader = false;
          } else {
            this.videoListDataSource.data = this.violations;
            this.loader = false;
          }
        },
        () => {
          this.loader = false;
          this.violations = [];
          this.videoListDataSource.data = this.violations;
        }
      );
  }

  private resetData() {
    this.violations = [];
    this.violationsData = [];
    this.dvrData = [];
    this.videoListDataSource.data = [];
    this.dvrOffset = 0;
    this.violationOffset = 0;
  }
}
