import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@app-core/services/data/data.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DriverManagementService } from '@app-driver-management/services/driver-management.service';
import { AIEventStats, AIRecognizedEventsResponse } from '@app-driver-management/common/driver-management.model';
import { AIRecognizedEventTypesMapping, AI_RECOGNIZED_EVENTS_COLUMNS } from '@app-driver-management/common/driver-management.constants';

@Component({
  selector: 'app-airecognized-events',
  templateUrl: './airecognized-events.component.html',
  styleUrls: ['./airecognized-events.component.scss'],
})
export class AIRecognizedEventsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('paginator', { static: true })
  public paginator: MatPaginator;

  @Input()
  public startDate: string;
  @Input()
  public endDate: string;
  @Input()
  public driverId: string;
  @Input()
  public fleetId: string;
  @Input()
  public showTitle: boolean = true;

  public tablePageSize: number = 5;
  public displayedColumns: string[] = AI_RECOGNIZED_EVENTS_COLUMNS;
  public loader: boolean = false;
  public aiRecognizedDataSource = new MatTableDataSource<AIEventStats>([]);
  public totalCount: number;

  private aiRecognizedList: AIEventStats[];
  private aiRecognizedEventLabels = AIRecognizedEventTypesMapping;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(public dataService: DataService, private driverManagementService: DriverManagementService) {}

  public ngOnInit(): void {
    if (this.loader) {
      this.aiRecognizedDataSource.data = new Array(5).fill(undefined);
    }
    this.aiRecognizedDataSource.paginator = this.paginator;
  }

  public ngAfterViewInit(): void {
    this.aiRecognizedDataSource.paginator = this.paginator;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ((changes?.startDate && changes.startDate?.currentValue) || (changes?.endDate && changes.endDate?.currentValue)) {
      this.paginator.firstPage();
      this.getAI_RecognizedEvents();
    }
  }

  private getAI_RecognizedEvents() {
    this.loader = true;
    const params = {
      startDate: this.startDate,
      endDate: this.endDate,
      fleetId: this.fleetId,
      driverId: this.driverId,
    };
    this.driverManagementService
      .getAIRecognizedEvents(params)
      .pipe(
        finalize(() => {
          this.loader = false;
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        (res: AIRecognizedEventsResponse) => {
          this.aiRecognizedList = res.stats;
          const filteredDriverList = this.aiRecognizedList.map((element) => {
            const foundEvent = this.aiRecognizedEventLabels.find((item) => item.eventType === element.eventType);
            const eventLabel = foundEvent ? foundEvent.eventLabel : element.eventType;
            return {
              ...element,
              eventLabel: eventLabel,
            };
          });
          this.totalCount = filteredDriverList.length;
          this.aiRecognizedDataSource.data = filteredDriverList;
          this.aiRecognizedDataSource.paginator = this.paginator;
        },
        () => {
          this.aiRecognizedList = [];
          this.aiRecognizedDataSource.data = this.aiRecognizedList;
        }
      );
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
